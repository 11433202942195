<template>
	<div class="desktopLayoutClass" >
		<v-app>

			<div>
                <v-row justify="center">
                  <v-dialog v-model="auth_error_enable" persistent max-width="290">
                    <v-card>
                      <v-card-title style="word-break:normal">
                        {{$t("Warning_Message.Unauthorized")}}
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                           {{$t("Warning_Message.Ok")}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>


			<div>
				<v-row justify="center">
					<v-dialog v-model="ageLimitModal" persistent max-width="290">
					<v-card>
						<v-card-title style="word-break:normal">
						  {{$t("Warning_Message.Invalid_Dependent_Please_Add_Them_Through_My_Linked_Contacts")}}
						</v-card-title>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" depressed @click="closeageLimitModal">
							  {{$t("Warning_Message.Ok")}}
						</v-btn>
						</v-card-actions>
					</v-card>
					</v-dialog>
				</v-row>
			</div>
			<div>
        <v-row justify="center">
          <v-dialog v-model="same_number" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               Cannot create a linked contact with your number.
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeSameNumber">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break:normal">
                 {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                  {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

			<div class="mainDiveditDependent">
			<div
				v-show="show_header"
				class="header"
				style="
				display: flex;
				flex-direction: row;
				padding-top: 10px;
				padding-left: 5px;
				"
				>
				<div style="display: flex; flex-direction: row; width: 100%">
					<div
					style="width: 10%; padding-top: 6px"
					align="left"
					v-on:click="redirectToMyFamily()"
					>
						<img
						src="https://img.icons8.com/material-outlined/20/000000/left.png"
						/>
					</div>
					<div style="width: 90%" align="left">
						<span style="font-weight: 600; font-size: 15pt"
						>{{ $t("Customer.EditLinkedContact.Edit_Linked_Contact") }}</span>
					</div>
				</div>
			</div>
			<div>
				<v-row justify="center">
					<v-dialog v-model="editProfileSuccessModal" max-width="290">
						<v-card>
							<v-card-title style="word-break:normal;margin-top:20px;">
								{{$t("Warning_Message.Linked_Contact_Edited")}}
							</v-card-title>
							<v-card-text
							></v-card-text
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									align="center"
									color="primary"
									depressed
									@click="closeEditProfileSuccessModal"
									>
									 {{$t("Warning_Message.Ok")}}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-row>
			</div>
			<div class="PageClass" style="padding: 10px 20px !important;background-color: #f5faff;margin-top:25px;">
				<p style="font-size:85%">{{ $t("Customer.EditLinkedContact.Validity") }}</p>
                    <p style="text-align: left; margin-bottom: 0px;font-weight: 600 !important;">{{ $t("Customer.EditLinkedContact.Relationship") }}</p>
                    <v-select
					v-if="$i18n.locale=='en'"
					:items="Relationship_list"
					v-model="dependent_relationship"
					placeholder="Relationship"
					style="width: 100%;
					border-radius: 10px;
					margin-right: 10px;
					margin-left: 0px;
					margin-top: 7px;"
					outlined
					item-text = "en_text"
					return-object
					background-color="#F4F6FA"
					:rules="relationshipRules"
					>
					</v-select>
					<v-select
					v-if="$i18n.locale=='mm'"
					:items="Relationship_list"
					v-model="dependent_relationship"
					placeholder="တော်စပ်မှု"
					style="width: 100%;
					border-radius: 10px;
					margin-right: 10px;
					margin-left: 0px;
					margin-top: 7px;"
					outlined
					item-text = "mm_text"
					return-object
					background-color="#F4F6FA"
					:rules="relationshipRulesMM"
					>
					</v-select>
          <p style="text-align: left; padding-bottom:0px; margin-bottom: 0px; margin-top:7px; font-weight: 600 !important;font-weight: 600 !important;">{{ $t("Customer.EditLinkedContact.Name") }}</p>
          <v-text-field
            v-model="dependent_name"
            outlined
            :placeholder="translation_enter_name"
            style="width: 100%; border-radius: 10px;margin-top:10px;"
            background-color="#F4F6FA"
            :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
          />
          <p style="text-align:left; padding-bottom:0px; margin-bottom: 0px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditLinkedContact.Date_Of_Birth") }}</p>
            <v-menu
			        ref="menu"
			        v-model="menu"
			        :close-on-content-click="false"
			        transition="scale-transition"
			        offset-y
			        min-width="auto"
			      	>
			        <template v-slot:activator="{ on, attrs }">
			          <v-text-field
			            v-model="linked_contact_dob"
			            :placeholder="translation_dob"
			             background-color="#F4F6FA"
			            :rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
			            outlined
			            v-bind="attrs"
			            v-on="on"
			            style="border-radius:8px;"
			          ></v-text-field>
			        </template>
			        <v-date-picker
			          v-model="linked_contact_dob"
			          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
			          min="1900-01-01"
			          @input="get_age(linked_contact_dob)"
			          @change=" menu= false"
					  		full-width
			        ></v-date-picker>
			      </v-menu>
      <v-row>
				<p style="text-align:left;padding-bottom: 0px; margin-bottom: 0px; margin-left:10px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditLinkedContact.Phone_Number") }}</p>
        <v-text-field
          v-model="dependent_phoneNumber"
          outlined
          disabled
          :placeholder="translation_enter_number"
					type="number"
          background-color="#F4F6FA"
          style=" width:90%; border-radius:10px; margin-right:10px; margin-left:10px; margin-top:10px"
          :rules="mobileNumberRules"
        >
        </v-text-field>
        <p style="text-align:left;padding-bottom: 0px; margin-bottom: 0px; margin-left:10px; margin-top:7px; font-weight: 600 !important;">{{ $t("Customer.EditLinkedContact.Gender") }}</p>
        <v-select
		  v-if="$i18n.locale=='en'"
          :items="gender"
          v-model="dependent_gender"
          outlined
          placeholder="Gender"
          background-color="#F4F6FA"
						item-text = "en_text"
          style="width:90%; border-radius:10px; margin-right:10px; margin-left:10px; margin-top:10px"
          :rules = "genderRules"
					return-object
        	>
        </v-select>
		<v-select
		  v-if="$i18n.locale=='mm'"
          :items="gender"
          v-model="dependent_gender"
          outlined
          placeholder="ကျား/မ"
          background-color="#F4F6FA"
			   	item-text = "mm_text"
          style=" width:90%; border-radius:10px; margin-right:10px; margin-left:10px; margin-top:10px"
          :rules = "genderRulesMM"
          return-object
         	>
        </v-select>
      </v-row>
      <v-row class="ButtonClass" style="margin-bottom: 10%; width: 100%">
    		<v-btn color="primary" style="width:100%; height: 48px; margin-right:10px; margin-left:10px; margin-top:10px"
					@click="saveDependentInfo"  :disabled="isButtonClicked || !(dependent_relationship != '' && dependent_name != '' && dependent_gender !='' && linked_contact_dob !=''  && dependent_phoneNumber !='') || ageLimitNotInRange">
          	{{ $t("Customer.EditLinkedContact.Save") }}
      	</v-btn>
      </v-row>
    </div>
  </div>
		</v-app>
	</div>
</template>

<script>
	import NavBar from "../../../views/navbar.vue";
	import axios from "axios";
	import bottomnavbar from "./bottomnavbar.vue";
	import moment from "moment"
	export default {
		name: "EditProfile",
		data(){
			return{
				dependent_name: "",
                menu:false,
				dependent_email : "",
				dependent_phoneNumber : "",
				dependent_nrc : "",
				dependent_smoker : "",
				linked_contact_dob: "",
				dependent_weight: "",
				dependent_height: "",
				dependent_gender: "",
				dependent_alcoholic: "",
				submit_button: "",
				auth_error_enable : false,
				error_enable : false,
				same_number : false,
				gender:  [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
				Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
				dependent_relationship: "",
				customer_id: "",
				customer_name: "",
				current_user_number : "",
				mobileNumber: "",
				currentCustomer: "",
				editProfileSuccessModal: false,
				dependent_body: "",
				dependent_id: "",
				alcoholicValueList :["Non drinker","Social drinker","Moderate drinker","Alcoholic"],
				smokerValueList : ["Yes","No"],
				isMobile : false,
				ageLimitModal : false,
				isButtonClicked: false,
				ageLimitNotInRange : false,
				relationshipRules: [(v) => !!v || "Relationship is required"],
				relationshipRulesMM: [(v) => !!v || "တော်စပ်ပုံထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				nameRules: [(v) => !!v || "Name is required"],
				nameRulesMM: [(v) => !!v || " အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။ "],
				nrcRules: [(v) => !!v || "NRC is required"],
				genderRules: [(v) => !!v || "Gender is required"],
				genderRulesMM: [(v) => !!v || "ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				weightRules: [(v) => !!v || "Weight is required"],
				emailRules: [(v) => !!v || "Email is required"],
				dobRules: [(v) => !!v || "DOB is required"],
				dobRulesMM: [(v) => !!v || "မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
				heightRules: [
				(v) => !!v || "Height is required",
				(v) => (v && v.length <= 3) || "Height cannot be more than 1000 cms",
				],
				weightRules: [
				(v) => !!v || "Weight is required",
				(v) => (v && v.length <= 3) || "Weight cannot be more than 1000 kgs",
				],
				mobileNumberRules: [
				v => !!v || 'Mobile Number is required',
				v => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
				v => v > 0 || 'Mobile number cannot be negative',
				],
				translation_enter_name: "Enter Name",
				translation_dob: "Date Of Birth",
				translation_enter_number: "Enter Phone Number",
				show_header: true,
			}
		},
		methods:{
			get_age(dateString) {
					var today = new Date();
					var birthDate = new Date(dateString);
					var age = today.getFullYear() - birthDate.getFullYear();
					var m = today.getMonth() - birthDate.getMonth();
					if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
						age--;
					}
					if(age <=18 && age >= 60){
						this.ageLimitNotInRange = true;
						this.ageLimitModal = true;
					}
					else{
						this.ageLimitNotInRange = false;
					}
  		},
      closeageLimitModal(){
         this.ageLimitModal = false;
      },
      closeSameNumber(){
         this.same_number = false;
      },
			closeDialogUnauthorized(){
				this.auth_error_enable = false;
				this.$router.push({
					name: "Login",
				});
			},
			closeDialogError(){
				this.error_enable = false;
			},
			redirectToMyFamily() {
				this.$router.go(-1);
			},
			closeEditProfileSuccessModal(){
				this.editProfileSuccessModal = false;
                 this.$router.push({
					name: "MyLinkedContacts",
				});
			},
			saveDependentInfo()
			{
				if(this.dependent_phoneNumber != this.current_user_number)
				{
					this.isButtonClicked = true;
					var dependent_body = {
			        linkedContactId: this.dependent_id,
			        linkedContactName: this.dependent_name,
					phoneNumber : this.dependent_phoneNumber,
			        relationshipWithCustomer: this.dependent_relationship,
			        linkedContactDob: this.linked_contact_dob,
			        gender: this.dependent_gender.en_text,
			        token : this.currentCustomer,
			        typeOfUser : "CUSTOMER"
			    };
			    axios
	        .post(
	          process.env.VUE_APP_BACKEND_URL + "/updateLinkedContactForCustomerId",
	          dependent_body
	        )
	        .then((updateLinkedContactForCustomerIdResponse) => {
	          var dependent_object = updateLinkedContactForCustomerIdResponse.data.data;
	          this.editProfileSuccessModal = true;
	        })
	        .catch((saveDependentInfoError) => {
	          if(saveDependentInfoError.response.status == 401){
	            this.auth_error_enable = true;
	            this.isButtonClicked = false;
	          }
	          else{
	            this.error_enable = true;
	            this.isButtonClicked = false;
	          }
	        });
	      }
	      else
	      {
	      	this.same_number = true;
	      }
			}
		},
		mounted(){
			var userAgent = navigator.userAgent
			if(userAgent.includes("kbzpay")){
			  this.show_header = false;
			}
			this.isMobile = this.$device.mobile;
			document.title = "Edit Linked Contact"
			this.$i18n.locale = this.$store.state.locale;
			if(this.$i18n.locale == "en")
			{
				this.translation_enter_name = "Enter Name";
				this.translation_dob = "Enter Date Of Birth";
				this.translation_enter_number = "Enter Phone Number";
			}
				else
			{
				this.translation_enter_name = "အမည်ထည့်မည်";
	      this.translation_dob = "မွေးနေ့";
	      this.translation_enter_number = "ဖုန်းနံပါတ်";
			}
			this.currentCustomer = this.$cookies.get("customerToken");
			var access = this.$cookies.get("access");
			if(!this.currentCustomer){
				this.$router.push({
					name : "Login"
				})
			}
			else{
				var verifyCustomerTokenData = {
					"token" : this.currentCustomer,
					"access" : access,
					"typeOfUser" : "CUSTOMER"
				}
				axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
				.then((verifyCustomerTokenDataResponse) => {
					this.customer_name = verifyCustomerTokenDataResponse.data.data.customer_name;
					this.current_user_number = verifyCustomerTokenDataResponse.data.data.phoneNumber;
					var params = this.$route.params;
					this.dependent_id = params["linkedContactId"];
					var fetchDependentBody = {
						'linked_contact_id': this.dependent_id,
						'token': this.currentCustomer,
						'typeOfUser': 'CUSTOMER'
					};
					axios.post(process.env.VUE_APP_BACKEND_URL+"/fetchParticularLinkedContact",fetchDependentBody)
					.then((fetchParticularLinkedContactResponse) => {
						this.dependent_object = fetchParticularLinkedContactResponse.data.data;
						this.dependent_name = this.dependent_object.linked_contact_name;
						this.dependent_phoneNumber = this.dependent_object.linked_contact_phoneNumber;
						var dependent_relationship = this.dependent_object.relationship_with_customer;
						let obj = this.Relationship_list.find(o => o.key === dependent_relationship.replace(" ","_").toLowerCase());
						this.dependent_relationship = obj;
						//this.linked_contact_dob = moment(this.dependent_object.linked_contact_dob).format('YYYY-MM-DD');
						this.linked_contact_dob = this.dependent_object.linked_contact_dob.split("T")[0];
						var dependentGender = this.dependent_object.linked_contact_gender;
						let objGender = this.gender.find(o => o.key === dependentGender.replace(" ","_").toLowerCase());
						this.dependent_gender = objGender
						//console.log(fetchParticularLinkedContactResponse);
					})
					.catch((fetchParticularLinkedContactError) => {
						alert(viewParticularDependentError);
					});
				})
				.catch((verifyCustomerTokenDataError)=>{
					if(verifyCustomerTokenDataError.response.status == 401){
						this.auth_error_enable = true;
					}
					else{
						this.error_enable = true;
					}
				})
    		}
		},
	}
</script>

<style scoped>
/* .v-picker--full-width {
  height: 500px;
} */
/* @media (min-width: 800px){
  .mainDiveditDependent{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
   .PageClass{
	 margin-top: 25px !important;
 }
  } */
</style>
